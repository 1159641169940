import { createContext, useContext, useState } from "react";
import { useFetchOfficeInfo } from "../hooks/useFetchOfficeInfo";

const OfficeInfoContext = createContext()

export const useOfficeContext = () => useContext(OfficeInfoContext)

const OfficeInfoProvider = ( { children } ) => {
    const { data, isLoading } = useFetchOfficeInfo()
    return (
        <OfficeInfoContext.Provider
            value = {{
                officeInfo: data,
                isLoading
            }}
        >
            { children }
        </OfficeInfoContext.Provider>
    )
}

export default OfficeInfoProvider