import React, {useEffect, useState} from 'react';
import {useFetchWadapatra} from '../hooks/useFetchWadapatra';
import {useNavigate, useParams} from 'react-router-dom';
import {useWadaPatraContext} from '../context/WadapatraContext';
import {useFetchSakhasWithWadapatra} from "../../sakhas/hooks/useFetchSakhasWithWadapatra";

const InnerPageSideBar = () => {
  const navigate = useNavigate();
  const {active, setActive, setWadasFetched, setIsWadas} = useWadaPatraContext();
  const id = useParams().id;
  const {data, isLoading} = useFetchWadapatra(id);
  const {data: shakhaData, loading: isShakhaLoading} = useFetchSakhasWithWadapatra();
  const [remainedShakhas, setRemainedShakhas] = useState([]);

  const handleClick = (item) => {
    navigate(`/inner/${id}?wadapatra=${item.id}`, {replace: true});
  }

  useEffect(() => {
    if (!isLoading) {
      setWadasFetched(true);
      if (data.items?.length === 0) {
        setIsWadas('nodata');
      } else {
        setActive(prev => prev || data.items[0].id);
      }
    }
  }, [data, isLoading, setWadasFetched, setIsWadas, setActive]);

  useEffect(() => {
    if (!isShakhaLoading) {
      let _remainedShakhas = shakhaData.filter((obj) => obj.id != id);
      setRemainedShakhas(_remainedShakhas);
      console.log('shakhaData', _remainedShakhas);
    }
  }, [shakhaData, isShakhaLoading]);

  return (
    <div
      className="w-[23%] blue-box-- flex-shrink-0 h-full top-0 left-0 flex flex-col gap-0 border-neutral-500 overflow-hidden border-r-2 border-r-[#d1d5db]">
      <div className="px-4 py-3 blue-box text-center text-white text-2xl font-bold">{data?.section?.title}</div>

      <div className="flex-grow w-full overflow-auto">
        <div className="w-full h-full flex flex-col gap-1 p-4 relative">
          {
            isLoading
              ? <></>
              : data?.items.length === 0
                ? (
                  <div role='button' className={`flex flex-row items-center px-4 py-4 rounded-xl bg-blue-950`}>
                    <div className="flex flex-col gap-2">
                      <span className='text-2xl font-semibold text-slate-200'>
                          यहाँ कुनै डाटा छैन।
                      </span>
                    </div>
                  </div>
                )
                : (
                  data?.items.map((item) => {
                    let isActive = item.id === active;
                    return (
                      <div key={item.id} onClick={() => handleClick(item)} role='button'
                           className={`flex flex-row items-center px-4 pt-4 pb-3 rounded-xl ${isActive ? 'red-box' : ''}`}>
                        <div className="flex flex-col gap-2" key={item.link}>
                          <span className='flex text-xl font-semibold'>
                              {item.title}
                          </span>
                        </div>
                      </div>
                    )
                  })
                )
          }
          {
            isShakhaLoading
              ? <></>
              : remainedShakhas?.map((shakha) => {
                return (
                  <>
                    <div key={shakha.id} role='button'
                       className={`px-4 py-3 blue-box text-center text-white text-2xl font-bold`}>
                      <span className='flex text-xl font-semibold'>
                        {shakha.title}
                      </span>
                    </div>
                    {shakha?.citizens_charters?.length > 0 && (
                      shakha?.citizens_charters?.map((item) => {
                        let isActive = item.id === active;
                        return (
                          <div key={item.id} onClick={() => handleClick(item)} role='button'
                               className={`flex flex-row items-center px-4 pt-4 pb-3 rounded-xl ${isActive ? 'red-box' : ''}`}>
                            <div className="flex flex-col gap-2" key={item.link}>
                              <span className='flex text-xl font-semibold'>
                                  {item.title}
                              </span>
                            </div>
                          </div>
                        )
                      })
                    )}
                  </>
                )
              })
          }
        </div>
      </div>

    </div>
  )
}

export default InnerPageSideBar
