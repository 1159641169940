import React from "react";
import {useNavigate} from "react-router-dom";
import { useFetchSuchanas } from '../hooks/useFetchSuchanas';
import CircularLoader from '../../../components/CircularLoader';
import { getNepaliDate } from '../../../libs/nepaliDateConvert';
import {
  MdOutlineUpdate
} from "react-icons/md";

const EntryPageSuchana = () => {
  const navigate = useNavigate()
  const { data, isLoading } = useFetchSuchanas()

  if (data?.length === 0) return <></>;

  return (
    <div className="top-0 left-0 w-full h-full flex flex-col gap-2 pl-4">
      <span className="flex text-2xl font-bold uppercase text-blue-800">
        <MdOutlineUpdate className="text-4xl" />&nbsp;<span className="pt-1">सूचना</span>
      </span>
      <div className="flex-grow relative w-full">
        {
          isLoading
            ? <CircularLoader />
            : data.length === 0
              ? <></>
              : (
                <div className="top-0 left-0 w-full h-full flex flex-col gap-2 overflow-auto no-scrollbar">
                  {
                    data?.map((item) => (
                      <div className="flex flex-col gap-1 py-2 border-b-2 border-b-gray-300 cursor-pointer" key={item.id} onClick={() => navigate(`/suchana/${item.id}`)}>
                        <span className="block text-lg font-bold">
                          {item?.title}
                        </span>
                        <span className="block text-sm">
                          {
                            getNepaliDate(item?.created_at).getFullDateInNepali()
                          }
                        </span>
                      </div>
                    ))
                  }
                </div>
              )
        }
      </div>
    </div>
  )
}

export default EntryPageSuchana
