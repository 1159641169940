import React from 'react';
import {
    MdInfoOutline,
    MdLayers, MdOutlineCall, MdOutlineComment,
    MdOutlineSupervisedUserCircle,
    MdOutlineSupervisorAccount, MdOutlineVideoLibrary, MdOutlineViewComfy
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import DisplayTime from "../libs/DisplayTime";
import {useOfficeContext} from "../features/officeInfo/context/OfficeInfoContext";

const entryItems = [
    {
        icon: <MdLayers className='text-amber-50 text-3xl' />,
        category: null,
        title: 'नागरिक वडापत्र',
        link: '/'
    },
    {
        icon: <MdOutlineSupervisedUserCircle className='text-amber-50 text-3xl' />,
        category: null,
        title: 'जन प्रतिनिधि',
        link: '/janapratinidhi',
        hide: process.env.REACT_APP_HAS_JANAPRATINIDHI !== 'true'
    },
    {
        icon: <MdOutlineSupervisorAccount className='text-amber-50 text-3xl' />,
        // category: 'सम्पर्क',
        category: null,
        title: 'कर्मचारीहरू',
        link: '/karmachari'
    },
    {
        icon: <MdInfoOutline className='text-amber-50 text-3xl' />,
        category: null,
        title: ' सूचनाहरू',
        link: '/suchana'
    },
    {
        icon: <MdOutlineVideoLibrary className='text-amber-50 text-3xl' />,
        category: null,
        title: ' भिडियो',
        link: '/video'
    },
    {
        icon: <MdOutlineViewComfy className='text-amber-50 text-3xl' />,
        category: null,
        title: ' कोठा नं.',
        link: '/room'
    },
    {
        icon: <MdOutlineCall className='text-amber-50 text-3xl' />,
        category: null,
        title: ' अत्यावश्यक सम्पर्क नं.',
        link: '/phonebook'
    },
    /*{
        icon: <MdOutlineComment className='text-amber-50 text-3xl' />,
        category: null,
        title: ' सुझाव तथा गुनासो',
        link: '/contact'
    }*/
];

const GlobalSidebar = () => {
    const navigate = useNavigate()
    const { officeInfo, isLoading } = useOfficeContext()

    if (isLoading) return

    return (
      <div className="flex flex-col overflow-auto gap-1 pr-4 border-r-2 border-r-[#d1d5db]">
          <div className="px-3 relative">
              <DisplayTime styling={{timeColor: '', dateColor: ''}}/>
          </div>
          {
              entryItems.map((item) => (
                item.hide ? null :
                <div
                  className="pl-3 rounded-r-lg fade-blue-box flex-grow flex flex-col justify-center w-[240px] flex-shrink-0"
                  style={{aspectRatio: '4.5/1'}}>
                    <div className="flex flex-row gap-2 items-center" role='button' onClick={() => navigate(item.link)}>
                        {item.icon}
                        <div className="text-amber-50">
                            {
                              item.category && (
                                <span className="block text-xl text-slate-200">
                                    {item.category}
                                </span>
                              )
                            }
                            <span className="block text-xl font-bold text-center">
                                {item.title}
                            </span>
                        </div>
                    </div>
                </div>
              ))
          }

          <div className="px-3 py-2 text-center font-[550] text-md">
              <p>{officeInfo.title}</p>
              <p>{officeInfo.address}</p>
              <p>{officeInfo.contact_no}</p>
              <p>{officeInfo.email}</p>
          </div>
      </div>
    )
}

export default GlobalSidebar
