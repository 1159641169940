import React, { useEffect } from 'react'
import { useOfficeContext } from '../features/officeInfo/context/OfficeInfoContext'

const Header = () => {
    const { officeInfo, isLoading } = useOfficeContext()

    if (isLoading) return

    const data = officeInfo

    return (
        <div className="flex flex-row gap-4 mx-auto">
            <div className='flex items-center flex-col'>
              {!! data.t1 && <span className="text-2xl font-extrabold text-slate-800">{data.t1}</span> }
              {!! data.t2 && <span className="text-2xl font-extrabold">{data.t2}</span> }
              {!! data.t3 && <span className="text-xl font-extrabold">{data.t3}</span> }
              {!! data.t4 && <span className="text-xl font-extrabold">{data.t4}</span> }
            </div>
        </div>
    )
}

export default Header
