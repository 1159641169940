import React, { useEffect, useState } from 'react'
import { getNepaliDate } from './nepaliDateConvert'
import { getCurrentTimeNepali } from './currentTime'

const initialValue = {
  date: getNepaliDate(new Date()).getFullDateInNepali(),
  hours: getCurrentTimeNepali().hours,
  minutes: getCurrentTimeNepali().minutes,
  seconds: getCurrentTimeNepali().seconds
}

function DisplayTime({ styling }) {
  const [dateTime, setDateTime] = useState(initialValue)

  const { date, hours, minutes, seconds } = dateTime

  useEffect(() => {
    const interval = setInterval(() => {
      const newDateTime = {
        date: getNepaliDate(new Date()).getFullDateInNepali(),
        hours: getCurrentTimeNepali().hours,
        minutes: getCurrentTimeNepali().minutes,
        seconds: getCurrentTimeNepali().seconds
      }
      setDateTime(prev => ({
        ...prev,
        ...newDateTime
      }))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className="flex flex-col items-center">
      <span className={`text-5xl font-extrabold relative block ${styling.timeColor}`}>
        {hours}:{minutes}
      </span>
      <span className={`text-lg ${styling.dateColor} font-semibold`}>
        {date}
        {/* मंगलवार, असोज १४ २०८० */}
      </span>
      <span className={`text-lg ${styling.dateColor} font-semibold`}>
        नेपाल संवत्: ११४४
      </span>
    </div>
  )
}

DisplayTime.defaultProps = {
  styling: {
    timeColor: 'text-slate-700',
    dateColor: 'text-slate-400',
  }
}

export default DisplayTime
