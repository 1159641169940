import React from "react";
import InnerPageLayout, { InnerPageHeader } from "../layouts/innerPage";
import CircularLoader from "../components/CircularLoader";
import {getNepaliDate} from "../libs/nepaliDateConvert";
import {useFetchSuchanaDetails} from "../features/suchanas/hooks/useFetchSuchanaDetails";
import {useParams} from "react-router-dom";

const SuchanaSingle = () => {
  let { id } = useParams();
  const { data, isLoading } = useFetchSuchanaDetails(id);

  return (
    <InnerPageLayout
      innerPageHeader={
        <InnerPageHeader />
      }
      innerPageSideBar={null}
      innerPageRightBar={null}
      innerPageContent={
        <div className='h-full flex-grow flex flex-col'>
          <div className="flex-grow w-full relative">
            <div className="absolute top-0 left-0 w-full h-full overflow-auto mask-overflow no-scrollbar">
              <div className="flex-grow relative w-full">
                {
                  isLoading
                    ? <CircularLoader/>
                    : !data
                      ? <></>
                      : (
                        <div className="top-0 left-0 w-full h-full flex flex-col gap-2 overflow-auto no-scrollbar">
                          <div className="flex flex-col gap-1 px-[100px] pt-6 pb-4 bg-[#fff] rounded" key={data.id}>
                            <span className="block text-2xl font-bold">
                                {data?.title}
                            </span>
                            <span className="block text-sm">
                              {
                                getNepaliDate(data?.created_at).getFullDateInNepali()
                              }
                            </span>
                            <br/>
                            <p dangerouslySetInnerHTML={{__html: data?.description}}/>
                          </div>
                        </div>
                      )
                }
              </div>
            </div>
          </div>
        </div>
      }
    />
  )
}

export default SuchanaSingle;
