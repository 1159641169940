import React, { useEffect, useRef } from 'react'
import { MdInfoOutline } from "react-icons/md";
import { useOfficeContext } from "../../features/officeInfo/context/OfficeInfoContext";
import Marquee from "react-fast-marquee";

const suchanas = [
    {
        id: 1,
        title: ' '
    },
    // {
    //     id: 2,
    //     title: 'जिल्ला प्रशासन कार्यालय, रुकुम (पश्चिम)'
    // },
    // {
    //     id: 3,
    //     title: 'जिल्ला प्रशासन कार्यालय, रुकुम (पश्चिम)'
    // },
    // {
    //     id: 4,
    //     title: 'जिल्ला प्रशासन कार्यालय, रुकुम (पश्चिम)'
    // },
    // {
    //     id: 5,
    //     title: 'जिल्ला प्रशासन कार्यालय, रुकुम (पश्चिम)'
    // }
]

function BottomInfoBar() {
    const { officeInfo, isLoading } = useOfficeContext()

    if (isLoading) return

    if (!officeInfo.footer_note) return

    return (
        <div className="py-3 z-50 relative text-slate-300 bg-slate-900">
            {/*<div className="w-full overflow-hidden" ref={ scrollableRef }>
                <div className="flex flex-row gap-16 items-center" ref={ toScrollRef }>
                    {
                        suchanas.map((item, idx) => (
                            <span className="text-xl font-semibold block flex-shrink-0" key={item.id}>
                                {item.title}
                            </span>
                        ))
                    }
                </div>
            </div>*/}
            <div className="w-full overflow-hidden">
                <div className="flex flex-row gap-16 items-center">
                    <Marquee className="overflow-hidden">
                        <span className="text-xl font-semibold block flex-shrink-0">
                            {officeInfo?.footer_note}
                        </span>
                    </Marquee>
                </div>
            </div>
            {/*<div className="absolute top-0 right-0 h-full px-8 py-2 bg-red-500 border-neutral-500/50 flex items-center z-[99]">
                <span className="text-3xl text-white font-semibold">
                    <MdInfoOutline className='text-amber-50 text-4xl' />
                </span>
            </div>*/}
        </div>
    )
}

export default BottomInfoBar
