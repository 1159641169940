import React, { useState } from 'react'
import InnerPageLayout, { InnerPageContent, InnerPageHeader, InnerPageRightBar } from '../layouts/innerPage'
import { InnerPageSideBar } from '../features/wadapatra'
import { navlinks } from '../constants/navlinks'
import WadaPatraProvider from '../features/wadapatra/context/WadapatraContext'

const InnerPage = () => {
    const [data, setData] = useState({})
    return (
        <WadaPatraProvider>
            <InnerPageLayout
                /*innerPageHeader={
                    <InnerPageHeader />
                }*/
                innerPageSideBar={
                    <InnerPageSideBar navLinks={navlinks} setData={setData} />
                }
                /*innerPageRightBar={
                    <InnerPageRightBar data={data} />
                }*/
                innerPageContent={
                    <InnerPageContent data={data} />
                }
            />
        </WadaPatraProvider>

    )
}

export default InnerPage
