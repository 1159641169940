import React from "react";
import InnerPageLayout, { InnerPageHeader } from '../layouts/innerPage'
import CircularLoader from "../components/CircularLoader";
import {useOfficeContext} from "../features/officeInfo/context/OfficeInfoContext";
import {splitDescription} from "../utils/splitDescription";
import {BiListPlus} from "react-icons/bi";

const Room = () => {
  const { officeInfo, isLoading } = useOfficeContext()

  if (isLoading) return

  return (
    <InnerPageLayout
      innerPageHeader={null}
      innerPageSideBar={null}
      innerPageRightBar={null}
      innerPageContent={
        <div className='h-full flex-grow flex flex-col'>
          <div className="flex-grow w-full relative">
            <div className="absolute top-0 left-0 w-full h-full overflow-auto mask-overflow no-scrollbar bg-[#fff]">
              <div className="flex-grow relative w-full">
                {
                  isLoading
                    ? <CircularLoader/>
                    : (
                      <div className="top-0 left-0 w-full h-full flex flex-col gap-2 overflow-auto no-scrollbar">
                        <div className="p-8">
                          {officeInfo.room_no_details ? splitDescription(officeInfo.room_no_details).map((item, idx) => (
                            <li className="text-2xl flex flex-row items-center gap-4 leading-9" key={idx}>
                              <BiListPlus className='flex-shrink-0'/>
                              {item}
                            </li>
                          )): 'हाल कुनै रेकर्ड प्रविष्ट गरिएको छैन।'}
                        </div>
                      </div>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      }
    />
  )
}

export default Room;
