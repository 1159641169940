import { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const WadaPatraContext = createContext()

export const useWadaPatraContext = () => useContext(WadaPatraContext)

const WadaPatraProvider = ( { children } ) => {
    const [searchParams] = useSearchParams()
    const [active, setActive] = useState(null)
    const [wadasFetched, setWadasFetched] = useState(false)
    const [isWadas, setIsWadas] = useState('idle')
    const [charterData, setCharterData] = useState({
        data: {},
        isLoading: true,
        error: null
    })

    const filterQuery = (wadaId) => {
        // let params = createSearchParams({ wadapatra: wadaId })
    }

    useEffect(()=>{
        // const queryParams = new URLSearchParams(searchParams.toString())
        setActive(prev=>Number(searchParams.get("wadapatra")) || prev)
    }, [searchParams])

    return (
        <WadaPatraContext.Provider
            value={{
                active,
                setActive,
                filterQuery,
                wadasFetched,
                setWadasFetched,
                isWadas,
                setIsWadas,
                charterData,
                setCharterData
            }}
        >
            { children }
        </WadaPatraContext.Provider>
    )
}

export default WadaPatraProvider
