import NepaliDate from "nepali-date-converter"

export const getNepaliDate = (inputDate = null) => {
    const utcStamp = new Date(inputDate).getTime()
    const date = new NepaliDate(utcStamp)
    NepaliDate.language = 'np'

    return {
        getFullDateInNepali: () => {
            return date.format('ddd DD, MMMM YYYY')
        }
    }
}
