import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useFetchSakhas } from '../hooks'

// const sakhas = [
//     'प्रमुख प्रशासकीय अधिकृतको सचिवालय', 'अन्तराष्ट्रिय सम्बन्ध सचिवालय', 'प्रशासन बिभाग', 'राजश्‍व बिभाग', 'कानुन तथा मानब अधिकार विभाग', 'आन्तरिक लेखापरीक्षण एकाइ', 'शहरी योजना आयोग (City Planning Commission)', 'उद्यान तथा हरियाली प्रवर्धन आयोजना', 'प्रमुख प्रशासकीय अधिकृतको सचिवालय', 'अन्तराष्ट्रिय सम्बन्ध सचिवालय', 'प्रशासन बिभाग', 'राजश्‍व बिभाग', 'कानुन तथा मानब अधिकार विभाग', 'प्रमुख प्रशासकीय अधिकृतको सचिवालय', 'अन्तराष्ट्रिय सम्बन्ध सचिवालय', 'प्रशासन बिभाग', 'राजश्‍व बिभाग', 'कानुन तथा मानब अधिकार विभाग', 'आन्तरिक लेखापरीक्षण एकाइ', 'शहरी योजना आयोग (City Planning Commission)'
//   ]

const EntryPageRooms = () => {
    const navigate = useNavigate()
    const { data, isLoading } = useFetchSakhas()

    const handleClick = (id) => {
        return ()=> navigate(`/inner/${id}`)
    }

    if(isLoading) return
    if(data.length===0) return

    return (
        <div className="w-full grid grid-cols-3 gap-4">
            {
                data.map((item) => (
                    <div
                        onClick={handleClick(item.id)}
                        role='button'
                        key={item.id}
                        style={{ aspectRatio: '1.6/1' }}
                        className="w-full rounded-lg snap-start blue-box overflow-hidden text-center p-8 flex flex-col gap-2 justify-center items-center"
                    >
                        <div className="text-2xl text-white">
                            {item.title}
                        </div>
                        {!!item.room_number && <p className="text-xl text-white">(कोठा नं.: {item.room_number})</p>}
                    </div>
                ))
            }
        </div>
    )
}

export default EntryPageRooms
