import axios from "../../../api/axios";

const SAKHA_URL = '/rooms';
const SAKHA_WITH_WADAPATRA_URL = '/rooms-with-wadapatra';

export const getSakhas = async () => {
    return axios.get(SAKHA_URL).then(res=> res.data);
}

export const getSakhasWithWadapatra = async () => {
    return axios.get(SAKHA_WITH_WADAPATRA_URL).then(res=> res.data);
}
