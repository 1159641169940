import React from 'react'

const EntryContent = ( { children } ) => {
  return (
    <div className='h-full'>
      {
        children
      }
    </div>
  )
}

export default EntryContent