import axios from '../../../api/axios'
import {useQuery} from "@tanstack/react-query";
import {fetchWadaPatraById} from "../../wadapatra/services/wadaPatraServices";

export const fetchKarmacharis = async () => {
    return axios.get('/employee').then(res=>res.data)
}

export const fetchInfoOfficer = async (id) => {
    return axios.get(`/employee/info-officer`).then(res=>res.data)
}
