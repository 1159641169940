export const navlinks = [
    {
        title: 'नागरिक वडापत्र',
        description:'नेपाल सरकार सङ्घीय मामिला तथा सामान्य प्रशासन मन्त्रालय राष्ट्रिय किताबखाना(प्रहरी) ',
        icon: '',
        link: '/'
    },
    {
        title: 'जनप्रतिनिधि',
        description:'माडी नगरपालिकाका नवनिर्वाचित जनप्रतिनिधिहरुको विवरण',
        icon: '',
        link:'/random1'
    },
    {
        title: 'कर्मचारीहरु',
        description:'नेपाल सरकार सङ्घीय मामिला तथा सामान्य प्रशासन मन्त्रालय राष्ट्रिय किताबखाना(प्रहरी)',
        icon: '',
        link:'/random2'
    },
    {
        title: 'सूचना',
        description:'नेपाल सरकार सङ्घीय मामिला तथा सामान्य प्रशासन मन्त्रालय राष्ट्रिय किताबखाना(प्रहरी)',
        icon: '',
        link:'/random3'
    },
    {
        title: 'भिडियो',
        description:'माडी नगरपालिकाका नवनिर्वाचित जनप्रतिनिधिहरुको विवरण',
        icon: '',
        link:'/random4'
    },

]