import React, {useEffect, useState} from "react";
import InnerPageLayout, { InnerPageHeader } from '../layouts/innerPage'
import {useFetchVideos} from "../features/videos/hooks/useFetchVideos";
import CircularLoader from "../components/CircularLoader";
import {getNepaliDate} from "../libs/nepaliDateConvert";
import {useNavigate} from "react-router-dom";
import HomeButton from "../components/HomeButton";
import {BiLeftArrowCircle, BiRightArrowCircle} from "react-icons/bi";

const Video = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useFetchVideos();
  const [ selectedData, setSelectedData ] = useState();

  useEffect(() => {
    if (!isLoading) {
      setSelectedData(data[0]);
    }
  }, [data, isLoading]);

  const handlePrevious = () => {
    let index = data.indexOf(selectedData);
    if (index === 0) {
      setSelectedData(data[data.length - 1]);
    } else {
      setSelectedData(data[index - 1]);
    }
  }

  const handleNext = () => {
    let index = data.indexOf(selectedData);
    if (index === data.length - 1) {
      setSelectedData(data[0]);
    } else {
      setSelectedData(data[index + 1]);
    }
  }

  return (
    <InnerPageLayout
      innerPageHeader={
        <div className="px-8 pt-2">
          <div className="flow-root">
            <p className="float-left flex">
              <button
                onClick={handlePrevious}
                type='button'
                className='text-2xl px-4 py-2 blue-box text-white rounded-lg flex flex-row gap-1 items-center'
              >
                <BiLeftArrowCircle/>
                <span>पछिल्लो भिडियो</span>
              </button>&nbsp;&nbsp;
              <button
                onClick={handleNext}
                type='button'
                className='text-2xl px-4 py-2 blue-box text-white rounded-lg flex flex-row gap-1 items-center'
              >
                <span>अघिल्लो भिडियो</span>
                <BiRightArrowCircle/>
              </button>
            </p>
            <p className="float-right">
              <HomeButton/>
            </p>
            {/*<p>Text!</p>*/}
          </div>
        </div>
      }
      innerPageSideBar={null}
      innerPageRightBar={null}
      innerPageContent={
        <div className='h-full flex-grow flex flex-col'>
          <div className="flex-grow w-full relative">
            <div className="absolute top-0 left-0 w-full h-full overflow-auto mask-overflow no-scrollbar">
              <div className="flex-grow relative w-full">
                {
                  isLoading
                    ? <CircularLoader/>
                    : data.length === 0
                      ? <></>
                      : (
                        <div className="top-0 left-0 w-full h-full flex flex-col gap-2 overflow-auto no-scrollbar">
                          {/*<div className="flex flex-col gap-1 px-[20px] pt-6 pb-4 bg-[#fff] rounded cursor-pointer"
                               key={item.id} onClick={() => navigate(`/video/${item.id}`)}>
                            <span className="block text-xl font-bold">
                                {item?.title}
                            </span>
                            <span className="block text-sm">
                              {
                                getNepaliDate(item?.created_at).getFullDateInNepali()
                              }
                            </span>
                          </div>*/}
                          <div className="overflow-hidden relative pb-[56.25%] h-0">
                            <iframe
                              src={`https://www.youtube.com/embed/${selectedData?.link}`}
                              title={selectedData?.title}
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              referrerpolicy="strict-origin-when-cross-origin"
                              allowFullScreen
                              className="absolute left-0 top-0 w-full h-full"
                            />
                          </div>
                        </div>
                      )
                }
              </div>
            </div>
          </div>
        </div>
      }
    />
  )
}

export default Video;
