import React from 'react'
import InnerPageLayout, { InnerPageHeader } from '../layouts/innerPage'
import { useFetchKarmachari } from '../features/karmachari/hooks/useFetchKarmachari'
import KarmachariCard from '../features/karmachari/components/KarmachariCard'
import CircularLoader from '../components/CircularLoader'

// const KarmachariItem = ({ data }) => {
//     return (
//         <div className="bg-white flex flex-col p-4 gap-2 w-full rounded-lg shadow-md ">
//             <div className='w-full flex justify-center mt-8'>
//                 <img
//                     src={data.image}
//                     alt="user name"
//                     className='w-1/3 aspect-square rounded-full object-cover'
//                 />
//             </div>
//             <div className="flex flex-col">
//                 <span className="block text-center text-2xl font-bold text-slate-700">
//                     {data.name}
//                 </span>
//                 <span className="block text-center text-xl text-slate-400">
//                     {data.post}
//                 </span>
//             </div>
//             <div className="p-2 rounded-lg bg-slate-100 flex-grow">
//                 <div className="grid grid-cols-2 gap-2">
//                     <div className="col-span-2">
//                         <div className="flex flex-row gap-4">
//                             <div className='flex flex-col '>
//                                 <span className="text-base text-slate-400">
//                                     जिम्मेवार साखा
//                                 </span>
//                                 <span className="text-lg  text-blue-500">
//                                     {data.sakha}
//                                 </span>
//                             </div>
//                             <div className='flex flex-col items-center flex-shrink-0 ml-auto'>
//                                 <span className="text-base text-slate-400">
//                                     कोथा नो
//                                 </span>
//                                 <span className="text-lg text-blue-500">
//                                     {data.room}
//                                 </span>
//                             </div>
//                         </div>
//                     </div>

//                     {
//                         data.email && (
//                             <div className="col-span-2 flex flex-row items-center gap-2 text-lg">
//                                 <MdMailOutline />
//                                 <span>
//                                     {data.email}
//                                 </span>
//                             </div>
//                         )
//                     }

//                     <div className="col-span-2 flex flex-row items-center gap-2 text-lg">
//                         <MdPhone />
//                         <span>
//                             +977 {data.phone}
//                         </span>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// const KarmachariItem = ({ data }) => {
//     return (
//         <div className="bg-white flex flex-col p-4 gap-2 w-full rounded-lg shadow-md ">
//             <div className='w-full flex justify-center mt-8'>
//                 <img
//                     src={data.image}
//                     alt="user name"
//                     className='w-1/3 aspect-square rounded-full object-cover'
//                 />
//             </div>
//             <div className="flex flex-col">
//                 <span className="block text-center text-2xl font-bold text-slate-700">
//                     {data.name}
//                 </span>

//             </div>
//             <div className="p-2 rounded-lg bg-slate-100 flex-grow">
//                 <div className="grid grid-cols-2 gap-2">
//                     <div className="col-span-2">
//                         <div className="flex flex-row gap-4">
//                             <div className='flex flex-col '>
//                                 <span className="text-base text-slate-400">
//                                     पद
//                                 </span>
//                                 <span className="text-lg  text-blue-500">
//                                     {data.post}
//                                 </span>
//                             </div>
//                         </div>
//                     </div>

//                     {
//                         data.email && (
//                             <div className="col-span-2 flex flex-row items-center gap-2 text-lg truncate">
//                                 <MdMailOutline />
//                                 <span>
//                                     {data.email}
//                                 </span>
//                             </div>
//                         )
//                     }

//                     <div className="col-span-2 flex flex-row items-center gap-2 text-lg">
//                         <MdPhone />
//                         <span>
//                             +977 {data.phone}
//                         </span>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

const Karmachari = () => {
    const { data, isLoading } = useFetchKarmachari()

    return (
        <InnerPageLayout
            /*innerPageHeader={
                <InnerPageHeader />
            }*/
            innerPageSideBar={null}
            innerPageRightBar={null}
            innerPageContent={
                <div className="h-full flex-grow flex flex-col">
                    <div className="flex-grow w-full relative">
                        <div className="absolute top-0 left-0 w-full h-full overflow-auto mask-overflow no-scrollbar">
                            {
                                isLoading
                                    ? <CircularLoader />
                                    : data?.length === 0
                                        ? <h1>No Data</h1>
                                        : (
                                            <div className="grid grid-cols-4 gap-8">
                                                {
                                                    data?.map((item) => (
                                                        <KarmachariCard key={item.id} data={item} />
                                                    ))
                                                }
                                            </div>
                                        )
                            }
                        </div>

                    </div>
                </div>
            }
        />
    )
}

export default Karmachari
