import React from 'react'
import DisplayTime from '../../libs/DisplayTime'
import Header from '../Header'
import {useOfficeContext} from "../../features/officeInfo/context/OfficeInfoContext";
import {useNavigate} from "react-router-dom";

const EntryHeader = () => {
  const navigate = useNavigate();
  const { officeInfo, isLoading } = useOfficeContext();
  if (isLoading) return;
  const data = officeInfo;

  return (
    <div className="flex flex-row justify-between items-center px-6 py-3 h-fit bg-[#eee] border-2 border-b-gray-300">
      <div className="relative h-20 w-24 mr-6 cursor-pointer" onClick={() => navigate('/')}>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/23/Emblem_of_Nepal.svg/1200px-Emblem_of_Nepal.svg.png"
          alt="nepal government"
          className="w-full h-full"
        />
      </div>

      <Header/>

      {!!data.image_path ?
        <div className="h-20">
          <img
            src={data.image_path}
            alt=""
            className="h-full px-4 bg-[#fff] mix-blend-multiply"
          />
        </div>:
        <div className="h-20">
          <img
            src={"https://www.abflags.com/_flags/flags-of-the-world/Nepal%20flag/Nepal%20flag-XXL-anim.gif"}
            alt=""
            className="h-full"
          />
        </div>
      }
    </div>

    /*<div className="flex flex-row justify-center items-center gap-4">
      <div className='h-full aspect-square relative bg-green-300'>
          <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/23/Emblem_of_Nepal.svg/1200px-Emblem_of_Nepal.svg.png"
                alt="nepal government"
                className='absolute h-full object-cover'
            />
        </div>
      <div className='flex items-center flex-col gap-2'>
          <span className='text-4xl font-extrabold text-slate-800'>ठोरी गाउँपालिका</span>
          <span className="text-3xl text-slate-600">घ्याङ्गडाँडा, पर्सा, मधेश प्रदेश, नेपाल</span>
          <span className="text-3xl text-slate-600">घ्याङ्गडाँडा, पर्सा, मधेश प्रदेश, नेपाल</span>
      </div>
    </div>*/

  )
}

export default EntryHeader
