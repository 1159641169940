import React, {useEffect} from 'react';
import {useWadaPatraContext} from '../context/WadapatraContext';
import {useFetchWadaDetails} from '../hooks/useFetchWadaDetails';
import {BiListPlus} from 'react-icons/bi';
import {splitDescription} from '../../../utils/splitDescription';
import CircularLoader from '../../../components/CircularLoader';
import NoData from '../../../components/NoData';
import './inner-page-content.css';

const InnerPageContent = () => {
  const {active, wadasFetched, isWadas, setCharterData, charterData} = useWadaPatraContext();

  const {data, isLoading} = useFetchWadaDetails(active);

  useEffect(() => {
    setCharterData(prev => ({
      ...prev,
      isLoading: isLoading
    }))
    if (!isLoading) {
      console.log("loaded successfully")
      setCharterData(prev => ({
        ...prev,
        data: data,
        error: null
      }))
    }
  }, [isLoading, data]);

  if ((wadasFetched && isWadas === 'nodata') || (!isLoading && Object.keys(data)?.length === 0)) {
    return (
      <NoData/>
    )
  }

  if (charterData?.isLoading) {
    return (
      <CircularLoader/>
    )
  }

  console.log("Wada details", charterData)

  return (
    <div className="w-full h-full flex flex-col overflow-auto">
      {!!charterData?.data?.audio_file && (
        <div className="pb-5">
          <audio controls autoPlay={true} className="w-full">
            <source src={process.env.REACT_APP_BACKEND_URL + "/storage/audio/" + charterData?.data?.audio_file}
                    type="audio/mpeg"/>
            Your browser does not support the audio element.
          </audio>
        </div>
      )}

      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-row gap-4 p-4 self-start">
          <div className="flex flex-col gap-4">
            <span
              className="text-xl block w-fit px-4 py-1 rounded-full bg-blue-500 text-slate-100 font-light">
                सेवा शुल्क
            </span>
            <span className="text-2xl text-slate-700 font-extrabold">
                {charterData?.data?.money}
            </span>
          </div>
        </div>
        <div className="flex flex-row gap-4 p-4 items-center self-start">
          <div className="flex flex-col gap-4">
            <span
              className="text-xl block w-fit px-4 py-1 rounded-full bg-blue-500 text-slate-100 font-light">
                लाग्ने समय
            </span>
            <span className="text-2xl text-slate-700 font-extrabold">
                {charterData?.data?.actual_time}
            </span>
          </div>
        </div>
      </div>

      {
        !!charterData?.data?.documents &&
        <div className="p-6 rounded-lg bg-white shadow-lg mb-4">
          <div>
            <span className='text-blue-500 text-2xl font-bold'>
                सेवाग्राहिले पुर्याउनुपर्ने प्रमाण तथा कागजात
            </span>
          </div>

          <div className="mt-4">
            <ul className='flex flex-col gap-2'>
              {splitDescription(charterData.data.documents).map((item, idx) => (
                <li className="text-xl flex flex-row items-center gap-4" key={idx}>
                  <BiListPlus className='flex-shrink-0'/>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      }

      {
        !!charterData?.data?.procedures &&
        <div className="p-6 rounded-lg bg-white shadow-lg mb-4">
          <div>
            <span className='text-blue-500 text-2xl font-bold'>
              सेवाग्राहिले पुर्याउनुपर्ने प्रकृया
            </span>
          </div>

          <div className="mt-4">
            <ul className='flex flex-col gap-2'>
              {splitDescription(charterData.data.procedures).map((item, idx) => (
              <li className="text-xl flex flex-row items-center gap-4" key={idx}>
                <BiListPlus className='flex-shrink-0'/>
                {item}
              </li>
              ))}
            </ul>
          </div>
        </div>
      }
    </div>
  )
}

export default InnerPageContent
