import React from "react";
import BackButton from "../../components/BackButton";
import HomeButton from "../../components/HomeButton";
// import Header from "../Header";

const InnerPageHeader = () => {
    return (
      <div className="px-8 py-4">
        <div className="flow-root">
          <p className="float-left">
            <BackButton/>
          </p>
          <p className="float-right">
            <HomeButton/>
          </p>
          {/*<Header />*/}
        </div>
      </div>
    )
}

export default InnerPageHeader;
