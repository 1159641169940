import { getNepaliNumber } from "./nepalinumber"

export const getCurrentTime = () => {
    let today = new Date()

    return {
        hours: today.getHours(),
        minutes: today.getMinutes().toString().padStart(2, '0'), // 1-9 -> "01", 5
        seconds: today.getSeconds().toString().padStart(2,'0')   // 1-9 -> "01"
    }
}

export const getCurrentTimeNepali = () => {
    let { hours, minutes, seconds } = getCurrentTime()
    return {
        hours: getNepaliNumber(hours),
        minutes: getNepaliNumber(minutes),
        seconds: getNepaliNumber(seconds)
    }
}