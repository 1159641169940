import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Route, Routes } from "react-router-dom";
import OfficeInfoProvider from "./features/officeInfo/context/OfficeInfoContext";
import EntryHeader from "./layouts/entryLayout/EntryHeader";
import GlobalSidebar from "./components/GlobalSidebar";
import BottomInfoBar from "./layouts/bottomInfo/BottomInfoBar";
import EntryPage from "./pages/EntryPage";
import InnerPage from "./pages/InnerPage";
import Janapratinidhi from "./pages/Janapratinidhi";
import Karmachari from "./pages/Karmachari";
import Suchana from "./pages/Suchana";
import SuchanaSingle from "./pages/SuchanaSingle";
import Room from "./pages/Room";
import Phonebook from "./pages/Phonebook";
import Contact from "./pages/Contact";
import Video from "./pages/Video";

function App() {
  const queryClient = new QueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <OfficeInfoProvider>
        <div className="h-screen flex flex-col bg-slate-100">
          <EntryHeader />

          <BottomInfoBar />

          <div className="h-full flex flex-row pt-4 overflow-hidden">
            <div>
              <GlobalSidebar />
            </div>

            <div className="h-full w-full flex flex-col">
              <div className="flex-grow relative">
                <div className="absolute top-0 left-0 w-full h-full">
                  <Routes>
                    <Route path="/" element={<EntryPage/>}/>
                    <Route path="/inner/:id" element={<InnerPage/>}/>
                    <Route path="/karmachari" element={<Karmachari/>}/>
                    <Route path="/janapratinidhi" element={<Janapratinidhi/>}/>
                    <Route path="/suchana" element={<Suchana/>}/>
                    <Route path="/suchana/:id" element={<SuchanaSingle/>}/>
                    <Route path="/phonebook" element={<Phonebook/>}/>
                    <Route path="/room" element={<Room/>}/>
                    <Route path="/video" element={<Video/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OfficeInfoProvider>
    </QueryClientProvider>
  );
}

export default App;
