import React from 'react'
import { BiArrowBack } from 'react-icons/bi'

const BackButton = () => {
    // const location = useLocation()

    const handleBack = () => {
        window.history.back()
    }

    return (
        <button
            onClick = { handleBack }
            type='button'
            className='text-2xl px-4 py-2 blue-box text-white rounded-lg flex flex-row gap-2 items-center'
        >
            <BiArrowBack />
            <span>पछाडि जानुहोस्</span>
        </button>
    )
}

export default BackButton
