import React from 'react'
import { MdMailOutline, MdPhone } from 'react-icons/md'

const KarmachariCard = ({ data }) => {
    return (
        <div className="bg-white flex flex-col p-4 gap-2 w-full rounded-lg shadow-md">
            <div className='w-full flex justify-center mt-8'>
                <img
                    src={data?.image_path}
                    alt={data?.name}
                    className='w-1/3 aspect-square rounded-full object-cover'
                />
            </div>
          <div className="flex flex-col">
                <span className="block text-center text-2xl font-bold text-slate-700">
                    {data?.name}
                </span>
                <span className="text-lg text-center">
                    {data?.position?.title}
                </span>
          </div>
          <div className="p-2 rounded-lg bg-slate-100 flex-grow">
            <div className="grid grid-cols-2 gap-2">
                    <div className="col-span-2">
                        <div className='flex flex-col'>
                            {/*<span className="text-base text-slate-400">
                                पद
                            </span>*/}
                        </div>
                    </div>

                    {
                        data?.room && (
                            <div className="col-span-2">
                                <div className='flex flex-col items-center flex-shrink-0 ml-auto'>
                                    <span className="text-base text-slate-400">
                                        कोठा नं.
                                    </span>
                                    <span className="text-lg text-blue-500">
                                        {data?.room}
                                    </span>
                                </div>
                            </div>
                        )
                    }

                    {
                        data.email && (
                            <div className="col-span-2">
                                {/*<MdMailOutline />*/}
                                <span className="break-words">
                                    {data?.email}
                                </span>
                            </div>
                        )
                    }

                    <div className="col-span-2">
                        {/*<MdPhone />*/}
                        <span className="break-words">
                            {data?.contact_no}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KarmachariCard
