import React from "react";
import InnerPageLayout, { InnerPageHeader } from '../layouts/innerPage'
import {useFetchSuchanas} from "../features/suchanas/hooks/useFetchSuchanas";
import CircularLoader from "../components/CircularLoader";
import {getNepaliDate} from "../libs/nepaliDateConvert";
import {useNavigate} from "react-router-dom";

const Suchana = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useFetchSuchanas()

  return (
    <InnerPageLayout
      innerPageHeader={null}
      innerPageSideBar={null}
      innerPageRightBar={null}
      innerPageContent={
        <div className='h-full flex-grow flex flex-col'>
          <div className="flex-grow w-full relative">
            <div className="absolute top-0 left-0 w-full h-full overflow-auto mask-overflow no-scrollbar">
              <div className="flex-grow relative w-full">
                {
                  isLoading
                    ? <CircularLoader/>
                    : data.length === 0
                      ? <></>
                      : (
                        <div className="top-0 left-0 w-full h-full flex flex-col gap-2 overflow-auto no-scrollbar">
                          {
                            data?.map((item) => (
                              <div className="flex flex-col gap-1 px-[20px] pt-6 pb-4 bg-[#fff] rounded cursor-pointer" key={item.id} onClick={() => navigate(`/suchana/${item.id}`)}>
                                <span className="block text-xl font-bold">
                                    {item?.title}
                                </span>
                                {/*<p dangerouslySetInnerHTML={{__html: item?.description}} />*/}
                                <span className="block text-sm">
                                  {
                                    getNepaliDate(item?.created_at).getFullDateInNepali()
                                  }
                                </span>
                              </div>
                            ))
                          }
                        </div>
                      )
                }
              </div>
            </div>
          </div>
        </div>
      }
    />
  )
}

export default Suchana;
