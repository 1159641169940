export const nepaliNumber = [
    '०', '१', '२', '३', '४', '५', '६', '७', '८', '९'
]

export const getNepaliNumber = number => {
    let strNum = ''+number
    let nepaliNumStr = ''

    for(let i = 0; i<strNum.length; i++) {
        nepaliNumStr += nepaliNumber[strNum[i]]
    }

    return nepaliNumStr
}