import { createSlice } from "@reduxjs/toolkit";

const mainLayoutSlice = createSlice({
    name: "mainLayout",
    initialState: {
        showSideBar: true,
        showSewaBar: false,
        sideBarContent: null,
    },
    reducers: {
        openSideBar: (state, action) => {
            if(state.showSideBar && action.payload === state.sideBarContent) return
            state.sideBarContent = action.payload
            state.showSideBar = true
        },
        openShowSewaBar: (state) => {
            if(state.showSewaBar) return
            state.showSewaBar = true
        },
        closeSideBar: (state) => {
            if(!state.showSideBar) return
            state.showSideBar = false
            state.sideBarContent = null
        },
        closeSewaBar: (state) => {
            if(!state.showSewaBar) return
            state.showSewaBar = false
        }
    }
})

export default mainLayoutSlice.reducer

export const { openSideBar, openShowSewaBar, closeSideBar, closeSewaBar } = mainLayoutSlice.actions

//selectors for this slice
export const selectShowSideBar = (state) => state.mainLayout.showSideBar
export const selectShowSewaBar = (state) => state.mainLayout.showSewaBar
export const selectSideBarContent = (state) => state.mainLayout.sideBarContent
export const selectSewaBarContent = (state) => state.mainLayout.sewaBarContent
