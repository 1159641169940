import React from 'react'
import InnerPageLayout, { InnerPageHeader } from '../layouts/innerPage'
import { MdMailOutline, MdPhone } from 'react-icons/md'
import {useFetchJanapratinidhi} from "../features/janapratinidhi/hooks/useFetchJanapratinidhi";
import CircularLoader from "../components/CircularLoader";
import KarmachariCard from "../features/karmachari/components/KarmachariCard";

const KarmachariItem = ({ data }) => {
    return (
        <div className="bg-white flex flex-col p-4 gap-2 w-full rounded-lg shadow-md">
            <div className='w-full flex justify-center mt-8'>
                <img
                    src={data.image}
                    alt="user name"
                    className='w-1/3 aspect-square rounded-full object-cover'
                />
            </div>
          <div className="flex flex-col">
              <span className="block text-center text-2xl font-bold text-slate-700">
                  {data.name}
              </span>
              <span className="text-lg text-center">
                  {data?.post}
              </span>
          </div>
          <div className="p-2 rounded-lg bg-slate-100 flex-grow">
            <div className="grid grid-cols-2 gap-2">
                    <div className="col-span-2">
                        <div className="flex flex-row gap-4">
                            <div className='flex flex-col'>
                                {/*<span className="text-lg text-blue-500">
                                    {data.post}
                                </span>*/}
                            </div>
                        </div>
                    </div>

                    {
                        data.email && (
                            <div className="col-span-2 flex flex-row items-center gap-2 text-lg truncate">
                                <MdMailOutline />
                                <span>
                                    {data.email}
                                </span>
                            </div>
                        )
                    }

                    <div className="col-span-2 flex flex-row items-center gap-2 text-lg">
                        <MdPhone />
                        <span>
                            +977 {data.phone}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Janapratinidhi = () => {
  const { data, isLoading } = useFetchJanapratinidhi()

    return (
        <InnerPageLayout
            /*innerPageHeader={
                <InnerPageHeader />
            }*/
            innerPageSideBar={null}
            innerPageRightBar={null}
            innerPageContent={
                <div className="h-full flex-grow flex flex-col">
                    <div className="flex-grow w-full relative">
                        <div className="absolute top-0 left-0 w-full h-full overflow-auto mask-overflow no-scrollbar">
                          {
                            isLoading
                              ? <CircularLoader />
                              : data?.length === 0
                                ? <h1>No Data</h1>
                                : (
                                  <div className="grid grid-cols-4 gap-8">
                                    {
                                      data?.map((item) => (
                                          <KarmachariCard key={item.id} data={item} />
                                      ))
                                    }
                                  </div>
                                )
                          }
                        </div>

                    </div>
                </div>
            }
        />
    )
}

export default Janapratinidhi
