import React, {useEffect} from 'react'
import EntryLayout from '../layouts/entryLayout'
import { EntryPageRooms } from '../features/sakhas'
import EntryPageSuchana from '../features/suchanas/components/EntryPageSuchana';
import {useFetchInfoOfficer} from "../features/karmachari/hooks/useFetchInfoOfficer";

const EntryPage = () => {
  const { data, isLoading } = useFetchInfoOfficer()

  useEffect(() => {
    if (!isLoading) {
      console.log(data)
    }
  }, [data]);

  return (
    <EntryLayout>
      <div className="h-full w-full">
        <div className="absolute top-0 left-0 w-full h-full px-6">
          <div className="flex flex-row h-full gap-8">
            <div className='w-3/4 relative flex-shrink-0'>
              <div className="absolute top-0 left-0 w-full h-full flex flex-col gap-8">
                <div className="flex-grow w-full relative overflow-auto no-scrollbar">
                  <EntryPageRooms/>
                  <br/>

                  {/*<div className="w-full aspect-video bg-gradient-to-br from-red-500 to-orange-600 relative">
                    <video
                      src="http://62.171.141.83:58/upload/e66443e81e8cc9c4fa5c099a1fb1bb87/files/अनलाइन_मार्फत_व्यक्तिगत_घटना_दर्ता।_राष्ट्रिय_परिचयपत्र_तथा_पञ्जिकरण_विभाग_।_2.mp4"
                      className='w-full h-full'
                      controls
                    />
                  </div>*/}
                  <br/>
                </div>
              </div>
            </div>
            <div className="flex-grow relative">
              <div className="absolute w-full h-full top-0 left-0 flex flex-col gap-8">
                <div className="w-full flex-grow relative border-l-2 border-l-[#d1d5db]">
                  <div className="max-h-[44%] overflow-y-auto">
                    <EntryPageSuchana/>
                  </div>
                  <br/>
                  <div className="max-h-[44%] overflow-y-auto">
                    <div className="p-4 text-center font-[550] pl-4">
                      <div className="mb-3">
                        <figure>
                          <img src={data?.image_path} alt="" className="h-[100px] w-auto mx-auto" />
                        </figure>
                      </div>
                      <h4 className="text-[1.4rem] font-bold">{data?.name}</h4>
                      <p className="text-[1.2rem] font-bold">सूचना अधिकारी</p>
                      <p className="text-[1.1rem]">{data?.contact_no}</p>
                      <p>{data?.email}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </EntryLayout>
  )
}

export default EntryPage
