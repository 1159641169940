import React from 'react'
import NoDataImg from '../assets/nodata.png'

const NoData = () => {
    return (
        <div className="flex-grow w-full h-full flex items-center flex-col my-10 gap-5">
            <img src={NoDataImg} alt="no data" className='w-[350px] object-cover' />

            <div className="text-center w-full">
                <span className="text-4xl font-semibold">
                    डाटा छैन
                </span>
            </div>
            <div className="text-center w-full">
                <span className="text-xl text-slate-600">
                    यहाँ कुनै डाटा छैन। कृपया केहि समय पछि पुन: प्रयास गर्नुहोस्
                </span>
            </div>
        </div>
    )
}

export default NoData