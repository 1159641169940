import React from 'react'
import { BiHome } from 'react-icons/bi'
import {useNavigate} from "react-router-dom";

const HomeButton = () => {
    const navigate = useNavigate();
    // const location = useLocation();

    const handleClick = () => {
        navigate("/");
    }

    return (
        <button
            onClick = { handleClick }
            type='button'
            className='text-2xl px-4 py-2 blue-box text-white rounded-lg flex flex-row gap-2 items-center'
        >
            <BiHome />
            <span>गृहपृष्ठ</span>
        </button>
    )
}

export default HomeButton;
