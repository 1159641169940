import React from 'react'
import InnerPageHeader from './InnerPageHeader'
import InnerPageContent from '../../features/wadapatra/components/InnerPageContent'
import InnerPageSideBar from '../../features/wadapatra/components/InnerPageSideBar'
import InnerPageRightBar from '../../features/wadapatra/components/InnerPageRightBar'
import { useFetchOfficeInfo } from '../../features/officeInfo/hooks/useFetchOfficeInfo'

const InnerPageLayout = ({ innerPageHeader, innerPageRightBar, innerPageSideBar, innerPageContent }) => {
    const { isLoading } = useFetchOfficeInfo()

    if(isLoading) return

    return (
        <div className='w-full h-full flex flex-row no-scrollbar overflow-hidden'>
            {innerPageSideBar}
            <div className="h-full flex-grow flex flex-col relative gap-2">
                {innerPageHeader}
                <div className="flex-grow h-full w-full p-8 pt-0">
                    <div className="h-full w-full flex flex-row gap-8">
                        <div className="flex-grow">
                            {innerPageContent}
                        </div>

                        {innerPageRightBar}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InnerPageLayout

export { InnerPageHeader, InnerPageContent, InnerPageSideBar, InnerPageRightBar }
