import React from 'react'
import EntryContent from './EntryContent'
import { useFetchOfficeInfo } from '../../features/officeInfo/hooks/useFetchOfficeInfo'
import EntryHeader from './EntryHeader'

const EntryLayout = ({ children }) => {
    const { isLoading } = useFetchOfficeInfo()
    if(isLoading) return
    return (
        <div className='w-full h-full flex flex-col relative'>
            {/*<EntryHeader />*/}
            <div className="basis-0 flex-grow relative">
                <div className="absolute top-0 left-0 w-full h-full flex flex-row">
                    {/* <EntrySide /> */}
                    <div className="flex-grow overflow-auto max-h-full">
                        <EntryContent>
                            {children}
                        </EntryContent>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EntryLayout
